export const DEFAULT_ICON = () => (
  <svg
    fill="currentColor"
    stroke-width="0"
    xmlns="http://www.w3.org/2000/svg"
    stroke="none"
    viewBox="0 0 448 512"
    height="1em"
    width="1em"
    style="overflow: visible;"
  >
    <path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V160h352v298c0 3.3-2.7 6-6 6z"></path>
  </svg>
);

export const MONTH_INCREMENT_ICON = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M16.0158 12.5L9.76579 18.75L8.646 17.6302L13.8022 12.5L8.67204 7.36979L9.79183 6.25L16.0158 12.5Z"
      fill="black"
    />
  </svg>
);

export const MONTH_DECREMENT_ICON = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M8.98421 12.5L15.2342 6.25L16.354 7.36979L11.1978 12.5L16.328 17.6302L15.2082 18.75L8.98421 12.5Z"
      fill="black"
    />
  </svg>
);

export const YEAR_INCREMENT_ICON = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3698 12.5L7.11979 18.75L6 17.6302L11.1563 12.5L6.02604 7.36979L7.14583 6.25L13.3698 12.5Z"
      fill="black"
    />
    <path
      d="M20.7394 12.5L14.4894 18.75L13.3696 17.6302L18.5259 12.5L13.3957 7.36979L14.5155 6.25L20.7394 12.5Z"
      fill="black"
    />
  </svg>
);
export const YEAR_DECREMENT_ICON = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6302 12.5L17.8802 6.25L19 7.36979L13.8437 12.5L18.974 17.6302L17.8542 18.75L11.6302 12.5Z"
      fill="black"
    />
    <path
      d="M4.26058 12.5L10.5106 6.25L11.6304 7.36979L6.47412 12.5L11.6043 17.6302L10.4845 18.75L4.26058 12.5Z"
      fill="black"
    />
  </svg>
);
